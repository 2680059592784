<template>
    <v-container style="height:1000px">
        <v-card>
            <p>{{$auth.user.name}}</p>

            <p>{{$auth.user.email}}</p>
            <v-img height="100" width="100" :src="$auth.user.picture"></v-img>
        </v-card>
    </v-container>
</template>
<script>
export default {
    data() {
        return {
            username: null,
            password: null
        }
    },
    
}
</script>
    
