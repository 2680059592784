<template>
  <v-container style="height: 1000px">
    <v-row>
      <v-col>
        <v-card class="my-4">
            <v-row>
                <v-col class="pa-5">
                    <v-img style="height:300px; width:300px; object-fit:cover" :src="listing.image"></v-img>
                </v-col>
            </v-row>
          <v-row>
            <v-col class="px-5" cols="12">
              <h3>{{ listing.title }} - Quebec</h3>
            </v-col>
             <v-col class="px-5" cols="12">
              <h5>{{ listing.location }}</h5>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  props: {
    id: {
      required: true
    }
  },
  data() {
    return {
      listing: null
    };
  },
  mounted() {
    const db = this.$firebase.firestore();
    db.collection("testListings", this.id)
      .get()
      .then((snap) => {
        const testCollection = [];
        snap.forEach((doc) => {
          testCollection.push({ [doc.id]: doc.data() });
        });
        this.listing = testCollection[0][this.id];
      });
  },
};
</script>
